import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const ResolutionCheckerContainer = styled.section`
  width: 100%;
  position: relative;
  background: ${(props) => props.theme.colors.primary};
  min-height: 100vh;
`;

export const ResolutionCheckerContent = styled.div`
  max-width: 450px;
  padding: 260px 20px 135px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 160px 20px 35px;
  }
`;

export const HeaderCaptionTypography = styled(Typography)`
  position: absolute;
  top: 25px;
  left: 25px;
  color: ${(props) => props.theme.colors.blue};
  font-size: 24px !important;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 18px !important;
  }
`;

export const TitleTypography = styled(Typography)`
  margin: 40px auto 275px;
  color: ${(props) => props.theme.colors.blue};
  font-size: 32px !important;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 26px !important;
    margin: 40px auto 150px;
    line-height: 1.2;
  }
`;

export const BodyTypography = styled(Typography)`
  color: ${(props) => props.theme.colors.dark_grey};
  line-height: 1.4 !important;
`;
